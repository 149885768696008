// set this up differently so that when the page is changed or filter applied, click still works and we don't need to rebind triggers
const BetterChoicesDialog4i = function(){
	let betterChoicesModal = new Dialog4i(),
	verificationToken, triggerEl,
	
	init = () => {
		verificationToken = document.querySelectorAll('input[name=__RequestVerificationToken]')[0].value;
		initDialog();
		
		document.getElementById("productContainer").addEventListener("click", events);
	},
	initDialog = () => {
		//const bcButtons = [].slice.call(document.querySelectorAll('.betterChoicesButton'));
		const bcLogo = '<svg height="32px" width="102px" role="img" aria-label="Better Choices"><title>Better Choices</title> <use xlink:href="#svgLogo-betterChoices"></use> </svg>';
		const dialogContent = '<div class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading better choices, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
	
		betterChoicesModal.Init({
			triggerElements: [], //bcButtons
			dialogClass: "betterChoicesContent smallDialog", 
			title: bcLogo, 
			content: dialogContent,
			onClose: close
			//onOpen: open
		});
	},	
	events = e => {
		if(e.target.classList.contains("betterChoicesButton")){
			triggerEl = e.target;
			betterChoicesModal.Open(e);
			open(e.target);
		}
	},
	open = el => {
		let productid = el.getAttribute('data-productid');
		if (!isNaN(productid)) fetchBCInfo(productid);
	},
	close = () => {
		setTimeout(() => {
			triggerEl.focus();
		}, 200);
	}
	fetchBCInfo = productId => {	
		fetch('/productdata/taggeddescription', { 
			method: 'POST', 
			body: JSON.stringify(productId), 
			headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', '__RequestVerificationToken': verificationToken, 'RequestVerificationToken': verificationToken }
		}).then(function(response){
			return  (response.ok) ? response.json() : []; // response.json() returns a promise
		}).then(function(response){
			document.getElementById("popUpDialog").querySelector(".dialogContent").innerHTML = response.html;
		}).catch(function(error){
			document.getElementById("popUpDialog").querySelector(".dialogContent").innerHTML = "<p class='text16'>Uh oh! We're having issues loading this information right now. Please try again later.</p>";
		});
	};
	return{
		Init: init
	};
};

(function(){
	const betterChoices = new BetterChoicesDialog4i();
	betterChoices.Init();
})();